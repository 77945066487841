import Image from 'next/image'
/* eslint-disable jsx-a11y/media-has-caption */
export function Album() {
	return (
		<div className="album">
			<h1 className="album-title">Sněhurka</h1>
			<div className="album-image">
				<Image src="/cover.png" layout="responsive" width="500px" height="500px" alt="Sněhurka" />
			</div>
			<div className="album-inner">
				<ul className="album-inner-tracks">
					<li>
						<span>1. Trpaslici manifest</span>
						<audio controls>
							<source src="/mp3/stopa1.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>2. Snehurcin zalozpev</span>
						<audio controls>
							<source src="/mp3/stopa2.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>3. Zrcadlo</span>
						<audio controls>
							<source src="/mp3/stopa3.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>4. Harampadi</span>
						<audio controls>
							<source src="/mp3/stopa4.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>5. Hezkej den</span>
						<audio controls>
							<source src="/mp3/stopa5.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>6. Les - Chodec</span>
						<audio controls>
							<source src="/mp3/stopa6.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>7. Les - U studanky</span>
						<audio controls>
							<source src="/mp3/stopa7.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>8. Les - Lov</span>
						<audio controls>
							<source src="/mp3/stopa8.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>9. Les - Requiem za lan</span>
						<audio controls>
							<source src="/mp3/stopa9.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>10. Az budu jednou velkej</span>
						<audio controls>
							<source src="/mp3/stopa10.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>11. Bozi holka</span>
						<audio controls>
							<source src="/mp3/stopa11.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>12. Ukolebavka</span>
						<audio controls>
							<source src="/mp3/stopa12.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>13. Otrava</span>
						<audio controls>
							<source src="/mp3/stopa13.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>14. Take it easy</span>
						<audio controls>
							<source src="/mp3/stopa14.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>15. Svatebni</span>
						<audio controls>
							<source src="/mp3/stopa15.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>16. Ten treti</span>
						<audio controls>
							<source src="/mp3/stopa16.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
					<li>
						<span>17. Snehurka is not dead</span>
						<audio controls>
							<source src="/mp3/stopa17.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</li>
				</ul>
			</div>
		</div>
	)
}
