import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { Album } from '../app/components/Album'
import { Thunder } from '../generated/content'
import { serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql/src/getZeusConnection'

export default function Page(props: CommonPageProps) {
	const url = props.data.getUrl
	const [accessAllowed, setAccessAllowed] = React.useState<boolean>(false)
	const urlAlreadyUsedThreeTimes = url?.usedTimes && url?.usedTimes >= 3

	const incrementUrlUsage = async (id: string, usage: number) => {
		const payload = {
			id: id,
			usedTimes: usage,
		}

		const res = await fetch('/api/url-increment', {
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
		})

		const result = await res.json()
		return result
	}

	function determinateAccess() {
		if (localStorage.getItem('minorCd') === url?.id) {
			setAccessAllowed(true)
			return
		}
		if (localStorage.getItem('minorCd') && url && !urlAlreadyUsedThreeTimes) {
			localStorage.setItem('minorCd', url?.id)
			//increment urls useage
			incrementUrlUsage(url.id, url.usedTimes ? url.usedTimes + 1 : 1)
			setAccessAllowed(true)
			return
		}
		if (!localStorage.getItem('minorCd') && url && !urlAlreadyUsedThreeTimes) {
			localStorage.setItem('minorCd', url?.id)
			//increment urls useage
			incrementUrlUsage(url.id, url.usedTimes ? url.usedTimes + 1 : 1)
			setAccessAllowed(true)
			return
		}
		if (urlAlreadyUsedThreeTimes) {
			setAccessAllowed(false)
			return
		}
	}
	React.useEffect(() => {
		determinateAccess()
	}, [])

	return (
		<div className="minorcds">
			<Head>
				<title>Minor Hudba</title>
			</Head>
			<div className={`minorcds-content ${!accessAllowed ? 'denied' : ''}`}>
				{accessAllowed ? <Album /> : <h1>Neplatný odkaz.</h1>}
			</div>
		</div>
	)
}

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const url = context.params?.page ? `hudba.minor.cz/${context.params.page}` : ''
	console.log('url', url)

	const dataFromContember = await content.query({
		getUrl: [
			{ by: { url: url } },
			{
				url: true,
				usedTimes: true,
				id: true,
			},
		],
	})

	return { props: { params: context.params, data: dataFromContember } }
})
